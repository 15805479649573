// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7ier48-1JpClr1t_c9L2-JA68NAJlADg",
  authDomain: "hour-dev-5edb6.firebaseapp.com",
  projectId: "hour-dev-5edb6",
  storageBucket: "hour-dev-5edb6.firebasestorage.app",
  messagingSenderId: "1043353168786",
  appId: "1:1043353168786:web:9ffbd244b789a5880b6f4b",
  measurementId: "G-XS9FMG2K70",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
