"use client";
import useFcmToken from "@/src/firebase/useFcmToken";
import { getMessaging, onMessage } from "firebase/messaging";
import firebaseApp from "./firebase";
import { useEffect } from "react";
import { toast } from "sonner";
import { createUserDevice } from "../actions/user/user-device";

export default function FirebaseForeground({ userId }: any) {
  const { token, notificationPermissionStatus } = useFcmToken();

  useEffect(() => {
    if (typeof window !== "undefined" && "serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope,
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });

      if (notificationPermissionStatus === "granted") {
        // Subscribe message
        const messaging = getMessaging(firebaseApp);
        const unsubscribe = onMessage(messaging, (payload) => {
          console.log(payload);
          toast.success(payload.data?.title, {
            action: {
              label: "View",
              onClick: () => {
                console.log(payload);
                if (payload.data?.link) {
                  window.location.href = payload.data?.link;
                }
              },
            },
          });
        });
        return () => {
          unsubscribe(); // Unsubscribe from the onMessage event on cleanup
        };
      }
    }
  }, [notificationPermissionStatus]);

  useEffect(() => {
    // Create user device token and store on BE side
    createUserDeviceToken();
  }, [token]);

  const createUserDeviceToken = async () => {
    console.log("createUserDeviceToken");
    if (!userId || !token) {
      return;
    }
    console.log("createUserDeviceToken", token, userId);
    await createUserDevice({ deviceToken: token, userId });
  };

  return null; // This component is primarily for handling foreground notifications
}
