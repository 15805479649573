"use client";

import { Button } from "antd";
import { LogOut } from "lucide-react";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useState } from "react";

export default function LogoutButton({ label, callbackUrl }: any) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const doSignOut = async () => {
    setIsLoading(true);
    await signOut({ redirect: false, callbackUrl });
    setIsLoading(false);
    router.refresh();
  };

  if (label && label.length > 0) {
    return (
      <Button
        onClick={doSignOut}
        className="border-slate-150 rounded-full border px-4 py-1 font-normal text-slate-500 transition-all duration-150 ease-in-out hover:bg-stone-200 active:bg-stone-300 dark:text-white dark:hover:bg-stone-700 dark:active:bg-stone-800"
        disabled={isLoading}
      >
        {label}
      </Button>
    );
  }
  return (
    <button
      onClick={() => signOut({ callbackUrl })}
      className="rounded-lg border-none bg-transparent p-1.5 text-stone-700 transition-all duration-150 ease-in-out"
      disabled={isLoading}
    >
      <LogOut width={18} />
    </button>
  );
}
